import React, { useState } from "react";

import { ArrowUpRight, Link03 } from "@hl/base-components/lib/assets/icons/HDS";
import { ArrowLeft } from "@hl/base-components/lib/assets/icons.generated";
import { OUTLINE_COLOR } from "@hl/base-components/lib/theme/button";
import { TEXT_COLOR } from "@hl/base-components/lib/theme/colors";
import { Metadata } from "@hl/shared-features/lib/features/gen-art/preview";
import CopyButton from "@hl/shared-features/lib/features/widgets/CopyButton";
import { Carousel, Embla, useAnimationOffsetEffect } from "@mantine/carousel";
import {
  createStyles,
  Box,
  Flex,
  Stack,
  Text,
  Group,
  Loader,
  Space,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";

import ReferralButton from "~features/share/ReferralButton";

import { getDetailPageUrl, MINT_BASE_URL } from "../../../config";

import { TokenRevealCarouselSlide } from "./TokenRevealCarouselSlide";

const useStyles = createStyles((theme) => ({
  headline: {
    fontSize: 96,
    [theme.fn.smallerThan("sm")]: {
      fontSize: 48,
    },
  },
  tagline: {
    maxWidth: 636,
    margin: "auto",
    textAlign: "center",
  },
  control: {
    border: "none",
    transition: "300ms",
    boxShadow: theme.shadows.sm,
    backgroundColor: theme.colors.white,
    opacity: 1,
    ":hover": {
      backgroundColor: theme.colors.white,
    },
    "& span": {
      margin: 0,
    },
  },
  controls: {
    padding: 0,
    margin: "0 8px",
    [theme.fn.largerThan("lg")]: {
      margin: "0 48px",
    },
    "[data-inactive]": {
      cursor: "not-allowed",
    },
  },
  viewport: {
    height: "100%",
    // padding: "0 20px",
    // [theme.fn.largerThan("sm")]: {
    //   padding: "0 40px",
    // },
    // [theme.fn.largerThan("lg")]: {
    //   padding: "0 80px",
    // },
  },
  container: {
    height: "100%",
  },
  link: {
    textDecoration: "none",
  },
}));

type TokenRevealCarouselProps = {
  mintedTokenIds?: string[] | null;
  collection: {
    id: string;
    onchainId: string;
    onChainBaseUri: string | null;
  };
  infoAreaRef: React.MutableRefObject<HTMLDivElement>;
  carouselRef: React.MutableRefObject<HTMLDivElement | null>;
  setIsCarouselReady: React.Dispatch<React.SetStateAction<boolean>>;
  isGenerative: boolean;
  height?: number;
  overrideImageUrl?: string;
};

const TokenRevealCarousel = ({
  mintedTokenIds,
  collection,
  infoAreaRef,
  carouselRef,
  setIsCarouselReady,
  isGenerative,
  height,
  overrideImageUrl,
}: TokenRevealCarouselProps) => {
  const TRANSITION_DURATION = 200;
  const { classes, theme } = useStyles(undefined);
  const isSmall = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`);
  const isMedium = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const isLarge = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [embla, setEmbla] = useState<Embla>();
  const [loadedImages, setLoadedImages] = useState(
    mintedTokenIds ? mintedTokenIds.map(() => false) : []
  );
  const [tokens, setTokens] = useState<{ id: string; metadata?: Metadata }[]>(
    mintedTokenIds?.map((mti) => ({ id: mti })) || []
  );
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  if (!mintedTokenIds?.length || !collection.onChainBaseUri) return <></>;

  const hasMultipleTokens = mintedTokenIds.length > 1;

  const arrowLeft = (
    <Flex
      p={isMedium ? 20 : 0}
      onClick={() => embla?.scrollPrev()}
      sx={{ cursor: "pointer" }}
    >
      <ArrowLeft width={24} height={24} style={{ cursor: "pointer" }} />
    </Flex>
  );

  const arrowRight = (
    <Flex
      sx={{ transform: "rotate(180deg)", cursor: "pointer" }}
      p={isMedium ? 20 : 0}
      onClick={() => embla?.scrollNext()}
    >
      <ArrowLeft width={24} height={24} />
    </Flex>
  );
  const selectedTokenId = mintedTokenIds[selectedSlide];
  const detailPathUrl = getDetailPageUrl(collection, selectedTokenId);

  return (
    <Stack align="center" justify="center" w="100%" px={{ base: 0, md: 80 }}>
      <Box pos="relative">
        {hasMultipleTokens && isMedium && (
          <>
            <Flex pos="absolute" h="100%" align="center" left={-76}>
              {arrowLeft}
            </Flex>
            <Flex pos="absolute" h="100%" align="center" right={-76}>
              {arrowRight}
            </Flex>
          </>
        )}
        <Carousel
          mx="auto"
          ref={carouselRef}
          onSlideChange={setSelectedSlide}
          loop
          speed={isLarge ? 100 : undefined}
          classNames={{
            controls: classes.controls,
            control: classes.control,
            viewport: classes.viewport,
            container: classes.container,
          }}
          styles={{
            slide: {
              alignSelf: "center",
              justifySelf: "center",
            },
          }}
          h="100%"
          align="center"
          withControls={false}
          withIndicators={false}
          slideGap={isSmall ? 20 : 160}
          includeGapInSize={false}
          getEmblaApi={(embla) => {
            setIsCarouselReady(true);
            setEmbla(embla);
          }}
          controlSize={64}
        >
          {mintedTokenIds.map((tokenId, index) => (
            <Carousel.Slide
              key={index}
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TokenRevealCarouselSlide
                collectionOnChainBaseUri={collection.onChainBaseUri!}
                tokenId={tokenId}
                isGenerative={isGenerative}
                onLoaded={(metadata) => {
                  setTokens((tokens) => {
                    tokens[index] = { id: tokenId, metadata };
                    return [...tokens];
                  });
                  setLoadedImages((arr) => {
                    arr[index] = true;
                    return [...arr];
                  });
                }}
                height={height}
                overrideImageUrl={overrideImageUrl}
              />
            </Carousel.Slide>
          ))}
        </Carousel>
      </Box>
      <Flex
        mb={20}
        align="center"
        w="100%"
        gap={12}
        justify="space-between"
        ref={infoAreaRef}
      >
        {hasMultipleTokens && !isMedium && arrowLeft}
        <Flex
          justify={{ base: "center", md: "space-between" }}
          align="center"
          direction={{ base: "column", md: "row" }}
          sx={{ flexGrow: 1 }}
        >
          <Group spacing={8} align="center">
            {loadedImages[selectedSlide] ? (
              <>
                <Link to={detailPathUrl} className={classes.link}>
                  <Group spacing={8}>
                    <Text size={14}>
                      {tokens[selectedSlide]?.metadata?.name}
                    </Text>
                    <ArrowUpRight
                      width={16}
                      color={theme.fn.themeColor(TEXT_COLOR.PRIMARY)}
                    />
                  </Group>
                </Link>
                <CopyButton
                  copyValue={`${MINT_BASE_URL}${detailPathUrl}`}
                  Icon={<Link03 width={14} />}
                />
              </>
            ) : (
              <Loader size="sm" />
            )}
            {!isSmall && <ReferralButton size="xs" color={OUTLINE_COLOR} />}
          </Group>
          <Text color={theme.colors.secondaryText[0]} size={14}>
            {selectedSlide + 1} of {mintedTokenIds.length} minted
          </Text>
          {isSmall && (
            <>
              <Space h={16} />
              <ReferralButton size="xs" color={OUTLINE_COLOR} />
            </>
          )}
        </Flex>
        {hasMultipleTokens && !isMedium && arrowRight}
      </Flex>
    </Stack>
  );
};

export default TokenRevealCarousel;
