const graphqlApiUrl = process.env.REACT_APP_WEB_GRAPHQL_URL ||
    process.env.NEXT_PUBLIC_WEB_GRAPHQL_URL ||
    "https://api.highlightstaging.xyz:8080";
const corsUrlRegex = new RegExp(`^${graphqlApiUrl.toString()}`);
export const sentryConfig = {
    dsn: process.env.REACT_APP_SENTRY_DSN ||
        process.env.NEXT_PUBLIC_SENTRY_DSN ||
        process.env.SENTRY_DSN,
    tracesSampleRate: +(process.env.REACT_APP_SENTRY_SAMPLE_RATE ||
        process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE ||
        process.env.SENTRY_SAMPLE_RATE ||
        "0.1"),
    environment: process.env.REACT_APP_HIGHLIGHT_ENV ||
        process.env.NEXT_PUBLIC_HIGHLIGHT_ENV ||
        process.env.HIGHLIGHT_ENV ||
        "development",
    tracePropagationTargets: [corsUrlRegex],
};
export const sentryOtelConfig = {
    ...sentryConfig,
    instrumenter: "otel",
};
