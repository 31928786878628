import { css, Global } from "@emotion/react";
import ColorSchemeContext, {
  ColorSchemeMode,
  UserColorSchemeSettings,
} from "@hl/base-components/lib/theme/ColorSchemeContext";
import { darkTheme } from "@hl/base-components/lib/theme/dark/theme";
import { lightTheme } from "@hl/base-components/lib/theme/light/theme";
import { useAppColorScheme } from "@hl/base-components/lib/theme/useColorScheme";
import { useAccountSettings } from "@hl/shared-features/lib/features/auth/hooks/useAccountSettings";
import { MantineProvider } from "@mantine/core";

import fonts from "../fonts";

const globalStyles = css`
  ${fonts}
  .no-child-table .mantine-ScrollArea-viewport > div {
    display: unset !important;
  }
`;

export const AppThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  // TODO: Is this needed?
  useAccountSettings();

  const { colorScheme, userColorSchemeSettings, setColorScheme } =
    useAppColorScheme({});

  return (
    <ColorSchemeContext.Provider
      value={{
        colorScheme,
        userColorSchemeSettings,
        selectUserColorScheme: async (_scheme) => {
          setColorScheme(
            _scheme === UserColorSchemeSettings.DARK
              ? ColorSchemeMode.DARK
              : ColorSchemeMode.LIGHT
          );
        },
      }}
    >
      <MantineProvider
        theme={colorScheme === ColorSchemeMode.DARK ? darkTheme : lightTheme}
        withGlobalStyles
        withNormalizeCSS
      >
        <Global styles={globalStyles} />
        {children}
      </MantineProvider>
    </ColorSchemeContext.Provider>
  );
};
