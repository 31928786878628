import { css } from "@emotion/react";
import AeonikProFixedMedium from "@hl/base-components/lib/assets/fonts/AeonikProFixed/AeonikProFixed-Medium.woff";
import AeonikProFixedMedium2 from "@hl/base-components/lib/assets/fonts/AeonikProFixed/AeonikProFixed-Medium.woff2";
import AeonikProFixedRegular from "@hl/base-components/lib/assets/fonts/AeonikProFixed/AeonikProFixed-Regular.woff";
import AeonikProFixedRegular2 from "@hl/base-components/lib/assets/fonts/AeonikProFixed/AeonikProFixed-Regular.woff2";
import { createFontFaces } from "@hl/base-components/lib/theme/fonts";

const AeonikProFixedFont = createFontFaces({
  name: "Aeonik Pro",
  normal: {
    400: [AeonikProFixedRegular, AeonikProFixedRegular2],
    500: [AeonikProFixedMedium, AeonikProFixedMedium2],
  },
});

/*
 * All fonts that can be loaded
 */
export const fontImports = css`
  ${AeonikProFixedFont}
`;

export default fontImports;
