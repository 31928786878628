export const sentryBrowserOptions = {
    // https://docs.sentry.io/platforms/javascript/legacy-sdk/tips/
    denyUrls: [
        // Google Adsense
        /pagead\/js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        /^chrome-extension:\/\//i,
    ],
};
