import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {};
export const SetColorSchemeDocument = gql `
  mutation SetColorScheme($colorScheme: String!) {
    setColorScheme(colorScheme: $colorScheme)
  }
`;
/**
 * __useSetColorSchemeMutation__
 *
 * To run a mutation, you first call `useSetColorSchemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetColorSchemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setColorSchemeMutation, { data, loading, error }] = useSetColorSchemeMutation({
 *   variables: {
 *      colorScheme: // value for 'colorScheme'
 *   },
 * });
 */
export function useSetColorSchemeMutation(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation(SetColorSchemeDocument, options);
}
export const GetUserAccountSettingsDocument = gql `
  query GetUserAccountSettings {
    getAccountSettings {
      id
      colorScheme
      displayName
      displayAvatar
      socials {
        email {
          address
          verifiedOn
        }
      }
    }
  }
`;
/**
 * __useGetUserAccountSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAccountSettingsQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery(GetUserAccountSettingsDocument, options);
}
export function useGetUserAccountSettingsLazyQuery(baseOptions) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery(GetUserAccountSettingsDocument, options);
}
