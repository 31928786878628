import React, { useEffect, useState } from "react";

import { Video } from "@hl/base-components/lib/video";
import { ResizedImage } from "@hl/shared-features/lib/features/image";
import { Box, useMantineTheme } from "@mantine/core";

import { ContentTypeInfo, getContentTypeInfo } from "~utils/contentType";

import { PageSupportedMediaType } from "../../../apollo/graphql.generated";

export const TokenRevealMedia = ({
  imageUrl,
  animationUrl,
  itemRef,
  customHeight,
}: {
  imageUrl?: string | null;
  animationUrl?: string | null;
  itemRef?: React.MutableRefObject<HTMLDivElement | null>;
  customHeight?: number;
}) => {
  const theme = useMantineTheme();
  const [contentType, setContentType] = useState<ContentTypeInfo | null>(null);

  useEffect(() => {
    if (animationUrl) {
      getContentTypeInfo(animationUrl).then(setContentType);
    }
  }, [animationUrl]);

  if (animationUrl && contentType?.type === PageSupportedMediaType.VIDEO) {
    return (
      <Box ref={itemRef}>
        <Video
          style={{
            maxHeight: customHeight ?? "unset",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            zIndex: 0,
            border: `0.5px solid ${theme.colors.divider[0]}`,
          }}
          autoPlay
          loop
          muted
          playsInline
        >
          <source src={animationUrl} />
        </Video>
      </Box>
    );
  }

  if (animationUrl && contentType?.type === PageSupportedMediaType.AUDIO) {
    return (
      <Box ref={itemRef}>
        <Video
          style={{
            maxHeight: customHeight ?? "unset",
            width: "100%",
            height: "100%",
            objectFit: "contain",
            zIndex: 0,
            border: `0.5px solid ${theme.colors.divider[0]}`,
          }}
          controls
          poster={imageUrl || undefined}
        >
          <source src={animationUrl} />
        </Video>
      </Box>
    );
  }

  if (imageUrl) {
    return (
      <Box ref={itemRef}>
        <ResizedImage
          sx={{
            width: "100%",
            border: `0.5px solid ${theme.colors.divider[0]}`,
          }}
          styles={{
            image: { maxHeight: customHeight ?? "unset" },
          }}
          src={imageUrl}
          fit="contain"
        />
      </Box>
    );
  }

  return <></>;
};
