import * as React from "react";
import { memo } from "react";

import loadable from "@loadable/component";
import { useParams } from "react-router-dom";

import useReferral from "~features/share/useReferral";

const MintPageApollo = loadable(
  () => import("../../features/MintPage/MintPageApollo")
);
const MintPageByCollectionId = () => {
  const { collectionId } = useParams();
  const { referrerQueryParamValue: referrer } = useReferral();

  return <MintPageApollo collectionId={collectionId} referrer={referrer} />;
};

export default memo(MintPageByCollectionId);
