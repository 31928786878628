import React, { memo } from "react";

import { Gift } from "@hl/base-components/lib/assets/icons.generated";
import { STATUS_COLOR } from "@hl/base-components/lib/theme/colors";
import { User } from "@hl/shared-features/lib/features/auth/User";
import { Flex, Group, Text, useMantineTheme } from "@mantine/core";

import { useGetMintSponsorQuery } from "~features/MintPage/sponsor-mints/queries.graphql.generated";

const SponsorBanner = ({
  sponsorMintOrderId,
  sponsoredVectorId,
}: {
  sponsorMintOrderId?: string;
  sponsoredVectorId?: string | null;
}) => {
  const theme = useMantineTheme();

  const { data, loading } = useGetMintSponsorQuery({
    variables: {
      sponsoredVectorId: sponsoredVectorId!,
      mintedOrderId: sponsorMintOrderId!,
    },
    skip: !sponsoredVectorId || !sponsorMintOrderId,
  });

  const user = data?.getMintSponsor.user;

  if (!sponsoredVectorId || !sponsorMintOrderId || loading || !user) {
    return <></>;
  }

  const textColor = theme.fn.themeColor(STATUS_COLOR.SUCCESS);
  return (
    <Flex align="center" wrap="nowrap" bg={STATUS_COLOR.SUCCESS_WASH} py={12}>
      <Group align="center" w="100%" spacing={6} position="center">
        <Gift color={textColor} width={16} height={16} />
        <Text size={"sm"} color={STATUS_COLOR.SUCCESS}>
          Mint sponsored by{" "}
        </Text>
        <User
          textColor={textColor}
          textSize={"sm"}
          displayName={user.displayName}
          walletAddresses={user.ensNames}
          enableLink
          small
        />
      </Group>
    </Flex>
  );
};

export default memo(SponsorBanner);
