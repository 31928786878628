import { memo } from "react";
import * as React from "react";

import { Text } from "@mantine/core";
import { useParams } from "react-router-dom";

import { useGetSalePageCollectionQuery } from "../queries.graphql.generated";

const emptyEdition = {
  onChainMetadata: "",
};

const TokenMetadata = () => {
  const { collectionId } = useParams();

  const { data: collectionData } = useGetSalePageCollectionQuery({
    variables: {
      collectionId: collectionId ?? "",
      withEns: false,
    },
  });

  const editions = collectionData?.getPublicCollection.editions ?? [
    emptyEdition,
  ];
  const metadataBase64 = editions[0].onChainMetadata ?? "";
  const metadataParsed = atob(
    metadataBase64.replace("data:application/json;base64,", "")
  );

  return <Text>{metadataParsed}</Text>;
};

export default memo(TokenMetadata);
