import { useEffect } from "react";

import { EXTERNAL_URL } from "~config";

export const HomePage = () => {
  useEffect(() => {
    window.location.replace(EXTERNAL_URL.HIGHLIGHT);
  }, []);
  return null;
};
