import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { useGetUserAccountSettingsLazyQuery } from "../queries/queries/accountSettings.graphql.generated";
import { accountSettingsVar, identityVar } from "../vars";
export const useAccountSettings = () => {
    const identity = useReactiveVar(identityVar);
    const [getUserAccountSettings] = useGetUserAccountSettingsLazyQuery({
        onCompleted: (data) => {
            const settings = data.getAccountSettings;
            accountSettingsVar({
                ...settings,
                loading: false,
            });
        },
    });
    useEffect(() => {
        if (!(identity === null || identity === void 0 ? void 0 : identity.account))
            return;
        getUserAccountSettings();
    }, [identity, getUserAccountSettings]);
};
